export const CONFIRM_ORDER = "CONFIRM_ORDER";

export const SELECT_ORDER_TYPE = "SELECT_ORDER_TYPE";
export const SET_ORDER_FORM_VALUES = "SET_ORDER_FORM_VALUES";
export const SET_ACCEPT_FORM_VALUES = "SET_ACCEPT_FORM_VALUES";

export const FETCH_ETH_CONTEXT = "FETCH_ETH_CONTEXT";
export const RECEIVE_ETH_CONTEXT = "RECEIVE_ETH_CONTEXT";

export const RECEIVE_AUTH = "RECEIVE_AUTH";

export const RECEIVE_LEVEL0_RESOURCE_BALANCES = "RECEIVE_LEVEL0_RESOURCE_BALANCES";

export const FETCH_BALANCE_WATER = "FETCH_BALANCE_WATER";
export const RECEIVE_BALANCE_WATER = "RECEIVE_BALANCE_WATER";

export const FETCH_EXTRACTION_RIGHT = "FETCH_EXTRACTION_RIGHT";
export const RECEIVE_EXTRACTION_RIGHT = "RECEIVE_EXTRACTION_RIGHT";
export const SET_ACTIVE_EXTRACTION_RIGHT = "SET_ACTIVE_EXTRACTION_RIGHT";

export const FETCH_WATER_ACCOUNTS = "FETCH_WATER_ACCOUNTS";
export const RECEIVE_WATER_ACCOUNTS = "RECEIVE_WATER_ACCOUNTS";
export const SET_ACTIVE_WATER_ACCOUNT = "SET_ACTIVE_WATER_ACCOUNT";

export const RECEIVE_EXTRACTION_RIGHTS = "RECEIVE_EXTRACTION_RIGHTS";
export const RECEIVE_ADMIN_EXTRACTION_RIGHTS = "RECEIVE_ADMIN_EXTRACTION_RIGHTS";

export const MODAL_ORDER_FORM_SET = "MODAL_ORDER_FORM_SET";
export const MODAL_ACCEPT_ORDER_SET = "MODAL_ACCEPT_ORDER_SET";

export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";

export const ORDER_TYPE_SELL = "sell";
export const ORDER_TYPE_BUY = "buy";
